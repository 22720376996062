<template>
	<el-dialog :visible.sync="show" width="70%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<el-row>
			<el-col :span="2">
				<span style="font-size: 16px;font-weight: bold;">课程简介：</span>
			</el-col>
			<el-col :span="20">
				<span>{{videoDesc}}</span>
			</el-col>
		</el-row>
		<el-row style="margin-top: 10px;">
			<el-col :span="2">
				<span style="font-size: 16px;font-weight: bold;">培训课件：</span>
			</el-col>
			<el-col :span="20">
				<video id="playVideos" class="videoClass" controls="controls" :poster="posterUrl"
				 :src="videoUrl"></video>
				<el-row type="flex" class="row-bg" justify="center" :gutter="22">
					<el-col :span="5" v-for="(item,index) in files" :key="index">
						<img @click="clickImg(item)" class="imgClass" :src="$fileUrl + item.coverUrl">
					</el-col>
				</el-row>
			</el-col>
		</el-row>
		
		<div class="boxShow">
			<div style="width: 100%;margin: 0 auto;font-size: 16px;">
				<el-row :gutter="22" style="padding-top: 10px;font-weight: bold;">
					<el-col>
						<div style="height: 25px;line-height: 25px;border-left: 3px solid #0c8b43;padding-left: 6px;">答题情况</div>
					</el-col>
				</el-row>
				<div v-if="listTopic1.length > 0" style="font-size: 16px;padding-top: 15px;">● 单选题</div>
				<div v-for="(item,index) in listTopic1" :key="index">
					<div style="padding-top: 15px;line-height: 25px;">
						<span style="color: #00be00;" v-if="JSON.stringify(item.correctOptions) === JSON.stringify(item.userOptions)">（√）</span>
						<span style="color: #ff5500;" v-if="JSON.stringify(item.correctOptions) !== JSON.stringify(item.userOptions)">（×）</span>
						{{item.topicTitle}}
					</div>
					<div class="optionClass">
						<div style="padding: 0 10px;line-height: 25px;" v-for="(items,indexs) in item.baseTopicOptions" :key="indexs">
							{{items.options}}, {{items.remarks}}
							<!-- <span class="xuanxiang" :class="{borderActive : items.isChoose === 0}">{{items.options}}</span>
						{{items.remarks}} -->
						</div>
					</div>
					<div style="padding-top: 20px;">
						正确答案：<span v-for="(items,indexs) in item.correctOptions" :key="indexs">{{items}}</span>
						您的答案：<span v-for="(items,indexs) in item.userOptions" :key="indexs">{{items}}</span>
					</div>
				</div>
				<div v-if="listTopic3.length > 0" style="font-size: 16px;padding-top: 15px;">● 判断题</div>
				<div v-for="(item,index) in listTopic3" :key="index">
					<div style="padding-top: 15px;line-height: 25px;">
						<span style="color: #00be00;" v-if="JSON.stringify(item.correctOptions) === JSON.stringify(item.userOptions)">（√）</span>
						<span style="color: #ff5500;" v-if="JSON.stringify(item.correctOptions) !== JSON.stringify(item.userOptions)">（×）</span>
						{{item.topicTitle}}
					</div>
					<div class="optionClass">
						<div style="padding: 0 10px;line-height: 25px;" v-for="(items,indexs) in item.baseTopicOptions" :key="indexs">
							{{items.options}}, {{items.remarks}}
						</div>
					</div>
					<div style="padding-top: 20px;">
						正确答案：<span v-for="(items,indexs) in item.correctOptions" :key="indexs">{{items}}</span>
						您的答案：<span v-for="(items,indexs) in item.userOptions" :key="indexs">{{items}}</span>
					</div>
				</div>
				<div v-if="listTopic2.length > 0" style="font-size: 16px;padding-top: 15px;">● 多选题</div>
				<div v-for="(item,index) in listTopic2" :key="index">
					<div style="padding-top: 15px;line-height: 25px;">
						<span style="color: #00be00;" v-if="JSON.stringify(item.correctOptions) === JSON.stringify(item.userOptions)">（√）</span>
						<span style="color: #ff5500;" v-if="JSON.stringify(item.correctOptions) !== JSON.stringify(item.userOptions)">（×）</span>
						{{item.topicTitle}}
					</div>
					<div class="optionClass">
						<div style="padding: 0 10px;line-height: 25px;" v-for="(items,indexs) in item.baseTopicOptions" :key="indexs">
							{{items.options}}, {{items.remarks}}
						</div>
					</div>
					<div style="padding-top: 20px;">
						正确答案：<span v-for="(items,indexs) in item.correctOptions" :key="indexs">{{items}}</span>
						您的答案：<span v-for="(items,indexs) in item.userOptions" :key="indexs">{{items}}</span>
					</div>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: this.dialog,
				loading: false,
				videoDesc: '',
				listTopic: [],
				listTopic1: [],
				listTopic2: [],
				listTopic3: [],
				files: [],
				posterUrl: '',
				videoUrl: '',
			}
		},
		created() {
			this.getList();
		},
		mounted() {},
		methods: {
			clickImg(obj){
				var vdo = document.getElementById("playVideos")
				this.posterUrl = this.$fileUrl + obj.coverUrl;
				this.videoUrl = obj.videoUrl;
				vdo.src = this.videoUrl;
				vdo.play();
			},
			getList() {
				this.loading = true;
				this.listTopic = [];
				this.listTopic1 = [];
				this.listTopic2 = [];
				this.listTopic3 = [];
				this.$axiosReq('/adapter/server/escort/web/alarmStudy/' + this.row.id, null, null, 'get').then(res => {
					this.videoDesc = res.data.videoDesc;
					if (res.data.videoInfoViewVos && res.data.videoInfoViewVos.length > 0) {
						this.files = res.data.videoInfoViewVos;
						this.posterUrl = this.$fileUrl + this.files[0].coverUrl;
						this.videoUrl = this.files[0].videoUrl;
					}
					if (res.data.topicInfos && res.data.topicInfos.length > 0) {
						this.listTopic = res.data.topicInfos;
						this.listTopic.forEach((item) => {
							if (item.topicType === 0) {
								this.listTopic1.push(item);
							} else if (item.topicType === 1) {
								this.listTopic2.push(item);
							} else if (item.topicType === 2) {
								this.listTopic3.push(item);
							}
						});
					} else {
						this.listTopic = [];
						this.listTopic1 = [];
						this.listTopic2 = [];
						this.listTopic3 = [];
					}
				}).finally(e => {
					this.loading = false
				})
			},
			close() {
				this.show = false
				this.$emit('refresh')
			}
		}
	}
</script>

<style scoped>
	.videoClass {
		display: block;
		width: 70%;
		height: 500px;
		margin: 0 auto;
		padding: 0;
	}
	
	.imgClass{
		display: block;
		width: 100%;
		height: 200px;
	}
	
	.row-bg{
		margin-top: 10px;
	}
	
	.boxShow {
		padding: 15px;
	}
	
	.optionClass {
		width: 100%;
		padding-top: 20px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-around;
	}
</style>
