<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<el-card>
			<div class="flex-row-box">
				<div class="pl-1 pr-1">
					<span class="text-primary text-pad-right">日期</span>
					<el-date-picker v-model="condition.date" type="daterange" class="input searchInput" range-separator="至"
					 start-placeholder="开始日期" end-placeholder="结束日期" clearable value-format="yyyy-MM-dd" size="mini" @change="changeTime" />
				</div>
				<div class="pl-1 pr-1">
					<span class="text-primary text-pad-right">报警类型</span>
					<el-select v-model="alarmType" clearable filterable size="mini" class="input searchInput" @change="alarmTypeChange">
						<el-option v-for="(item,index) in alarmTypeList" :key="index" :value="item.id" :label="item.alarmEvent" />
					</el-select>
				</div>
				<div class="pl-1 pr-1">
					<span class="text-primary text-pad-right">报警事件</span>
					<el-select v-model="alarmEvent" clearable collapse-tags size="mini" class="input searchInput" @change="changeCondition">
						<el-option v-for="(item,index) in alarmEventList" :key="index" :value="item.id" :label="item.alarmEvent" />
					</el-select>
				</div>
				<div v-if="companyType" class="pl-1 pr-1">
					<span class="text-primary text-pad-right">企业名称</span>
					<el-select v-model="companyId" clearable filterable size="mini" class="input searchInput" @change="changeCondition">
						<el-option v-for="(item,index) in company" :key="index" :value="item.id" :label="item.companyName" />
					</el-select>
				</div>
				<div class="flex-1"></div>
				<div class="pl-1 pr-1">
					<el-input v-model="condition.search" size="mini" class="input searchInput" suffix-icon="el-icon-search"
					 placeholder="搜索" @input="changeCondition" style="width: 260px;" />
				</div>
			</div>
		</el-card>
		<el-card style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col></el-col>
							<el-col :span="1">
								<vxe-button @click="shuaxin">刷新</vxe-button>
							</el-col>
							<el-col :span="1">
								<vxe-button v-if="$hasBtn('sys:permissions:report:struggle:learn:import')" @click="defenseExport">导出Excel</vxe-button>
							</el-col>
							<el-col :span="1"></el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="620" row-id="ID" :sort-config="{remote: true}" :filter-config="{remote: true}"
				 :checkbox-config="{reserve: true}" :data="table.list" :loading="loading">
					<vxe-table-column title="姓名" field="userName" />
					<vxe-table-column title="手机号" field="phone" />
					<vxe-table-column title="车牌号" field="carNum" />
					<vxe-table-column title="车辆自编号" field="carNo" />
					<vxe-table-column v-if="companyType" title="企业名称" field="companyName" show-overflow="title" />
					<vxe-table-column title="违规事件" field="alarmEvent" />
					<vxe-table-column title="违规时间" field="alarmDateTime" />
					<vxe-table-column title="学习状态" field="studyStatus" />
					<!-- <vxe-table-column title="考试成绩" field="score" /> -->
					<vxe-table-column title="考试成绩">
						<template v-slot="{ row }">
							<span v-if="row.score === 0">0</span>
							<span v-if="row.score !== 0 && !row.score">--</span>
							<span v-if="row.score > 0">{{row.score}}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column title="学习内容">
						<template v-slot="{ row }">
							<el-link type="primary" @click.native=" openDialog( { title: '学习内容' , addOr: 'upd' , }, row ) ">查看</el-link>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
				 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		bytesToFile
	} from '@/utils/handleByte'
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import {
		activeDefense,
		warnTypeSelect,
		activeDefenseExport,
		company
	} from '@/api'
	import Operate from './Operate'
	export default {
		name: 'Index',
		components: {
			Operate
		},
		data() {
			return {
				loading: false,
				condition: {
					releaseStatus: [],
					search: '',
					date: [],
					start: '',
					end: ''
				},
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 20,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					show: false
				},
				company: [],
				loginInfo: {},
				companyId: '',
				warnType: [],
				companyType: false,
				alarmTypeList: [],
				alarmEventList: [],
				alarmEvent: '',
				alarmType: '',
			}
		},
		created() {
			// this.openDialog({ title: '应急处置操作指南', addOr: 'add', });
			this.getCompany();
			this.getAlarmType();

			if (this.$companyType == 0) {
				this.companyType = true;
			} else {
				this.companyType = false;
			}
		},
		methods: {
			rowStyle,
			headerStyle,
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true
				this.dialog.show = true
				this.dialog.info = info
				this.dialog.row = row
			},
			changeCondition() {
				this.table.currentPage = 1
				this.getList()
			},
			shuaxin() {
				this.table.currentPage = 1;
				// this.companyId = '';
				this.condition.start = '';
				this.condition.end = '';
				this.condition.search = '';
				this.condition.releaseStatus = [];
				this.condition.date = [];
				this.getList()
			},
			defenseExport() {
				const loading = this.$loading({
					lock: true,
					text: '正在导出！',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				})
				this.$axiosReq('/adapter/server/escort/web/alarmStudy/export', null, {
					alarmEventId: this.alarmEvent,
					companyId: this.companyId,
					alarmTypeId: this.alarmType,
					beginTime: this.condition.start ? this.condition.start + ' 00:00:00' : null,
					endTime: this.condition.end ? this.condition.end + ' 00:00:00' : null,
					keyword: this.condition.search,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					bytesToFile(res.data.bytes, res.data.fileName)
				}).finally(() => {
					loading.close()
				})
			},
			changeTime(v) {
				this.table.currentPage = 1
				if (v === null) {
					this.condition.start = ''
					this.condition.end = ''
				} else {
					this.condition.start = v[0]
					this.condition.end = v[1]
				}
				this.getList()
			},
			getList() {
				this.loading = true
				this.$axiosReq('/adapter/server/escort/web/alarmStudy/page', null, {
					alarmEventId: this.alarmEvent,
					alarmTypeId: this.alarmType,
					companyId: this.companyId,
					beginTime: this.condition.start ? this.condition.start + ' 00:00:00' : null,
					endTime: this.condition.end ? this.condition.end + ' 00:00:00' : null,
					keyword: this.condition.search,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(Number(res.data.totalElements))
				}).finally(e => {
					this.loading = false
				})
			},
			remove(info, row) {
				const id = []
				if (info.del === 'batch') {
					const arr = this.$refs.table.getCheckboxRecords()
					const arr1 = this.$refs.table.getCheckboxReserveRecords()
					const batchDel = arr.concat(arr1)
					for (const i of batchDel) {
						id.push(i.id)
					}
					if (id.length === 0) {
						this.$message.error('请勾选！！！')
						return
					}
					this.$confirm('确认删除吗！！！', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$axiosReq(journalism, id, null, 'delete').then(res => {
							this.$message.success(res.msg)
							this.getList(this.condition.company)
						}).finally(e => {
							// id = []
						})
					}).catch(() => {
						this.$message.info('取消了删除！！！')
					})
				} else {
					this.$confirm('确认删除吗！！！', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$axiosReq(journalism + row.id, null, null, 'delete').then(res => {
							this.$message.success(res.msg)
							this.getList(this.condition.company)
						})
					}).catch(() => {
						this.$message.info('取消了删除！！！')
					})
				}
			},
			getAlarmType() {
				this.loading = true
				this.$axiosReq('/adapter/server/escort/web/alarmConfig/alarmType', null, null, 'get').then(res => {
					this.alarmTypeList = res.data;
				}).finally(e => {
					this.loading = false
				})
			},
			alarmTypeChange() {
				this.loading = true
				this.alarmEvent = '';
				if (!this.alarmType) {
					this.alarmEventList = []
					this.getList()
					return false;
				}
				this.$axiosReq('/adapter/server/escort/web/alarmConfig/alarmEvent/' + this.alarmType, null, null, 'get').then(res => {
					this.alarmEventList = res.data;
					this.getList();
				}).finally(e => {
					this.loading = false
				})
			},
			getCompany() {
				this.$axiosReq(company, null, {
					currentPage: 1,
					pageSize: 1000,
				}, 'get').then(res => {
					if (res.data.content && res.data.content.length > 0) {
						this.company = res.data.content;
						this.companyId = res.data.content[0].id;
						this.getList();
					}
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			},
			refreshDialog() {
				this.dialog.refresh = false
			}
		}
	}
</script>

<style scoped>

</style>
